import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, NoteAdd as NoteAddIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const SuperuserBilling = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [bills, setBills] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBill, setSelectedBill] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [notes, setNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/billing");
      setBills(response.data);
      setFilteredBills(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch bills data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBills(
      bills.filter(
        (bill) =>
          bill.clientName.toLowerCase().includes(value) ||
          bill.email.toLowerCase().includes(value)
      )
    );
  };

  const handleEditIconClick = (bill) => {
    setSelectedBill(bill);
    setFormData(bill);
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (bill) => {
    setSelectedBill(bill);
    setOpenDeleteModal(true);
  };

  const handleNotesIconClick = (bill) => {
    setSelectedBill(bill);
    setOpenNotesModal(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!validateEmail(formData.email)) {
      setSnackbar({ open: true, message: "Invalid email format", severity: "error" });
      setIsSubmitting(false);
      return;
    }
    try {
      await axios.put(`https://api.cope.ke/billing/${selectedBill.id}`, formData);
      fetchBills();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Bill updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update bill data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://api.cope.ke/billing/${selectedBill.id}`);
      fetchBills();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Bill deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete bill data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleNotesSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`https://api.cope.ke/billing/${selectedBill.id}/notes`, { notes });
      fetchBills();
      setOpenNotesModal(false);
      setSnackbar({ open: true, message: "Notes added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add notes", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredBills.map((bill) => (
        <Card key={bill.id} sx={{ width: 300, m: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar src={bill.avatar || ''}>
                {!bill.avatar && <FontAwesomeIcon icon={faUser} size="2x" />}
              </Avatar>
              <Box>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEditIconClick(bill)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDeleteIconClick(bill)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Notes">
                  <IconButton onClick={() => handleNotesIconClick(bill)}>
                    <NoteAddIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{bill.clientName}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faEnvelope} /> {bill.email}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faPhone} /> {bill.phone}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faDollarSign} /> {bill.amount}
            </Typography>
            <Typography variant="body2">
              Status:{" "}
              {bill.status === "unpaid" ? (
                <Chip label="Unpaid" color="error" />
              ) : (
                <Chip label="Paid" color="success" />
              )}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Billing</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search bills"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ mr: 2 }}
        />
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredBills.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No bills found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Edit Bill</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="clientName"
            label="Client Name"
            name="clientName"
            value={formData.clientName || ""}
            onChange={(e) => setFormData({ ...formData, clientName: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={formData.phone || ""}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="amount"
            label="Amount"
            name="amount"
            value={formData.amount || ""}
            onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="status"
            label="Status"
            name="status"
            value={formData.status || ""}
            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openNotesModal} onClose={() => setOpenNotesModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleNotesSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Notes</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="notes"
            label="Notes"
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this bill?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserBilling;
