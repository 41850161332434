import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Breadcrumbs,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faTasks,
  faDollarSign,
  faUsers,
  faComments,
  faUserMd,
  faCartPlus,
  faBlog,
  faMapMarkerAlt,
  faEnvelopeOpenText,
  faFileAlt,
  faUserCircle,
  faArrowLeft,
  faSignOutAlt,
  faCog,
  faRecycle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Component Imports (Assumed to be available)
import SuperuserAppointments from "../components/SuperuserAppointments";
import SuperuserTherapyTreatment from "../components/SuperuserTherapyTreatment";
import SuperuserTherapyAssessments from "../components/SuperuserTherapyAssessments";
import SuperuserUserAccounts from "../components/SuperuserUserAccounts";
import SuperuserTickets from "../components/SuperuserTickets";
import SuperuserSettings from "../components/SuperuserSettings";
import SuperuserResourceCenters from "../components/SuperuserResourceCenters";
import SuperuserBlogPosts from "../components/SuperuserBlogPosts";
import SuperuserCalendars from "../components/SuperuserCalendars";
import SuperuserSubscriptions from "../components/SuperuserSubscriptions";
import SuperuserBilling from "../components/SuperuserBilling";
import SuperuserClients from "../components/SuperuserClients";
import SuperuserMap from "./SuperuserMap";
import SuperuserTherapyPostRecovery from "./SuperuserTherapyPostRecovery";
import SuperuserQuestionnaires from "./SuperuserQuestionnaires"

const AdminInbox = () => {
  const navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [mainMenu, setMainMenu] = useState(true); // Track if we are on the main menu
  const [subMenu, setSubMenu] = useState(null); // Track if a sub-menu is selected
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("Home");

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Define icons for the main menu
  const mainMenuIcons = [
    { name: "Therapy", icon: faUserMd, subMenu: "therapy" },
    { name: "Financials", icon: faDollarSign, subMenu: "financials" },
    { name: "Administration", icon: faUserCircle, subMenu: "administration" },
    { name: "Calendar", icon: faCalendarAlt, component: "Calendar" },
    { name: "Settings", icon: faCog, component: "Settings" },
  ];

  // Define sub-menu icons for Therapy
  const therapySubMenuIcons = [
    { name: "Appointments", icon: faCalendarAlt, component: "Appointments" },
    { name: "Responses", icon: faComments, component: "Responses" },
    { name: "Treatment", icon: faTasks, component: "Treatments" },
    { name: "Post Recovery", icon: faRecycle, component: "PostRecovery" },
    { name: "Questionnaire ", icon: faFileAlt, component: "Questionnaire" },
    { name: "Resource Centers", icon: faMapMarkerAlt, component: "ResourceCenters" },
    { name: "Maps", icon: faMapMarkerAlt, component: "Map" },
  ];

  // Define sub-menu icons for Financials
  const financialSubMenuIcons = [{ name: "Billing", icon: faDollarSign, component: "Billing" }];

  // Define sub-menu icons for Administration
  const adminSubMenuIcons = [
    { name: "Ticketing", icon: faEnvelopeOpenText, component: "Tickets" },
    { name: "Content Marketing", icon: faBlog, component: "Blog" },
    { name: "Subscriptions", icon: faCartPlus, component: "Subscriptions" },
    { name: "Clients", icon: faUsers, component: "Clients" },
    { name: "User Accounts", icon: faUserCircle, component: "UserAccounts" },
  ];

  // Handle navigation back to main menu
  const handleBackToMainMenu = () => {
    setSelectedComponent(null);
    setSubMenu(null);
    setMainMenu(true);
    setBreadcrumbTitle("Home");
  };

  // Handle click on main menu or sub-menu icons
  const handleMainMenuClick = (menu) => {
    if (menu === "Logout") {
      handleLogout();
    } else if (menu === "therapy" || menu === "financials" || menu === "administration") {
      setSubMenu(menu);
      setMainMenu(false);
      setBreadcrumbTitle(menu.charAt(0).toUpperCase() + menu.slice(1)); // Set breadcrumb title dynamically
    } else {
      setSelectedComponent(menu);
      setMainMenu(false);
      setBreadcrumbTitle(menu); // Set the breadcrumb to component name
    }
  };

  // Render icons based on whether we are in main menu or sub-menu
  const renderIcons = (icons) => (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: "center" }}>
          <IconButton
            onClick={() =>
              icon.subMenu
                ? handleMainMenuClick(icon.subMenu)
                : setSelectedComponent(icon.component)
            }
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={icon.icon} />
          </IconButton>
          <Typography variant="body2" sx={{fontWeight:'bold'}}>{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  // Render the selected component
  const renderComponent = () => {
    switch (selectedComponent) {
      case "Appointments":
        return <SuperuserAppointments />;
      case "Responses":
        return <SuperuserTherapyAssessments />;
      case "Billing":
        return <SuperuserBilling />;
      case "PostRecovery":
        return <SuperuserTherapyPostRecovery />;
      case "Treatments":
        return <SuperuserTherapyTreatment />;
      case "Questionnaire":
        return <SuperuserQuestionnaires />;
      case "ResourceCenters":
        return <SuperuserResourceCenters />;
      case "Map":
        return <SuperuserMap />;
      case "Tickets":
        return <SuperuserTickets />;
      case "Blog":
        return <SuperuserBlogPosts />;
      case "Subscriptions":
        return <SuperuserSubscriptions />;
      case "Clients":
        return <SuperuserClients />;
      case "UserAccounts":
        return <SuperuserUserAccounts />;
      case "Calendar":
        return <SuperuserCalendars />;
      case "Settings":
        return <SuperuserSettings />;
      default:
        return mainMenu ? renderIcons(mainMenuIcons) : null;
    }
  };

  const handleLogout = () => {
    navigate('/');
    setSnackbar({ open: true, message: 'Logged out successfully', severity: 'success' });
  };

  return (
    <Container sx={{ mt: 8, mb: 4 }}>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{color:'#2C1C18'}}>
        <Link underline="hover" color="inherit" onClick={handleBackToMainMenu} style={{ cursor: "pointer" }}>
          Home
        </Link>
        {subMenu && !selectedComponent && <Typography color="text.primary">{breadcrumbTitle}</Typography>}
        {selectedComponent && <Typography color="text.primary">{breadcrumbTitle}</Typography>}
      </Breadcrumbs>

      {/* Render Main Menu or Sub Menu */}
      {mainMenu && !selectedComponent && renderIcons(mainMenuIcons)}

      {/* Sub Menu Rendering */}
      {!mainMenu && !selectedComponent && subMenu === "therapy" && (
        <>
          {renderIcons(therapySubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "financials" && (
        <>
          {renderIcons(financialSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "administration" && (
        <>
          {renderIcons(adminSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}

      {/* Render Selected Component */}
      {selectedComponent && (
        <Box sx={{ mt: 4, mb: 4 }}>
          {renderComponent()}
          <Box sx={{ textAlign: "center", my: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Centered Logout Button */}
      {mainMenu && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <IconButton
            onClick={handleLogout}
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
          </IconButton>
          <Typography color="#F9A64F" variant="body2" sx={{fontWeight:"bold"}}>Logout</Typography>
        </Box>
      )}

      {/* Snackbar for feedback messages */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ open: false })}>
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminInbox;
