import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon
} from "@mui/icons-material";
import axios from "axios";

const UserAssessment = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [assessments, setAssessments] = useState([]);
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchAssessments();
  }, []);

  const fetchAssessments = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/assessments");
      setAssessments(response.data);
      setFilteredAssessments(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch assessments", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAssessments(
      assessments.filter(assessment =>
        assessment.provider.toLowerCase().includes(value) ||
        assessment.status.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (assessment) => {
    setSelectedAssessment(assessment);
  };

  const handleBackClick = () => {
    setSelectedAssessment(null);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAssessments.map((assessment) => (
            <TableRow key={assessment.id} onClick={() => handleRowClick(assessment)}>
              <TableCell>{new Date(assessment.date).toLocaleDateString()}</TableCell>
              <TableCell>{assessment.provider}</TableCell>
              <TableCell>{assessment.status}</TableCell>
              <TableCell>
                <Button variant="outlined" onClick={() => handleRowClick(assessment)}>
                  View Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Assessment Details</Typography>
      <Typography variant="body1"><strong>Date:</strong> {new Date(selectedAssessment.date).toLocaleDateString()}</Typography>
      <Typography variant="body1"><strong>Provider:</strong> {selectedAssessment.provider}</Typography>
      <Typography variant="body1"><strong>Status:</strong> {selectedAssessment.status}</Typography>
      <Typography variant="body1"><strong>Details:</strong> {selectedAssessment.details}</Typography>
      <Button variant="contained" color="primary" sx={{ mt: 2 }}>
        Your Feedback
      </Button>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Your Assessments</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search assessments"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedAssessment ? (
        renderDetails()
      ) : (
        renderTable()
      )}
    </Container>
  );
};

export default UserAssessment;
