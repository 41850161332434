import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Breadcrumbs,
  Link,
  IconButton,
  Badge,
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faTasks,
  faDollarSign,
  faComments,
  faUserMd,
  faCartPlus,
  faMapMarkerAlt,
  faEnvelopeOpenText,
  faCalendarCheck,
  faFileAlt,
  faUserCircle,
  faArrowLeft,
  faSignOutAlt,
  faCog,
  faQuestionCircle,
  faMap,
  faCommentDots,
  faRecycle
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import UserAppointment from "../components/UserAppointment";
import UserAssessment from "../components/UserAssessment";
import UserSessions from "../components/UserSessions";
import UserTreatments from "../components/UserTreatments";
import UserPostRecovery from "../components/UserPostRecovery";
import UserSubscriptions from "../components/UserSubscriptions";
import UserSettings from "./UserSettings";
import { UserContext } from "../UserContext"; // Import UserContext

const UserInbox = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext); // Access user and logout
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [mainMenu, setMainMenu] = useState(true); // Track if we are on the main menu
  const [subMenu, setSubMenu] = useState(null); // Track if a sub-menu is selected
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("Home");

  const icons = [
    { name: "Appointments", icon: faCalendarAlt, component: 'Appointments', badgeCount: 5 },
    { name: "Responses", icon: faComments, component: 'Responses', badgeCount: 3 },
    { name: "Billing", icon: faDollarSign, component: 'Billing', badgeCount: 7 },
    { name: "Sessions", icon: faUserMd, component: 'Sessions', badgeCount: 2 },
    { name: "Treatments", icon: faTasks, component: 'Treatments', badgeCount: 4 },
    { name: "Post Recovery", icon: faCalendarCheck, component: 'PostRecovery', badgeCount: 6 },
    { name: "Assessments", icon: faFileAlt, component: 'Assessments', badgeCount: 3 },
    { name: "Feedback", icon: faCommentDots, component: 'Feedback', badgeCount: 4 },
    { name: "Our Shop", icon: faCartPlus, component: 'OurShop', badgeCount: 6 },
    { name: "Calendar", icon: faCalendarAlt, component: 'Calendar', badgeCount: 2 },
    { name: "Map", icon: faMap, component: 'Map', badgeCount: 0 },
    { name: "Chat", icon: faEnvelopeOpenText, component: 'Chat', badgeCount: 5 },
    { name: "Help", icon: faQuestionCircle, component: 'Help', badgeCount: 0 },
    { name: "Settings", icon: faCog, component: 'Settings', badgeCount: 0 },
    { name: "Logout", icon: faSignOutAlt, component: 'Logout', badgeCount: 0 },
  ];

  useEffect(() => {
    setLoading(false);  // Simulating loading finished
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
    setSnackbar({ open: true, message: 'Logged out successfully', severity: 'success' });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ open: false, message: '', severity: 'success' });
  };

// Define icons for the main menu
const mainMenuIcons = [
  { name: "Therapy", icon: faUserMd, subMenu: "therapy" },
  { name: "Financials", icon: faDollarSign, subMenu: "financials" },
  { name: "Calendar", icon: faCalendarAlt, component: "Calendar" },
  { name: "Settings", icon: faCog, component: "Settings" },
];

// Define sub-menu icons for Therapy
const therapySubMenuIcons = [
  { name: "Appointments", icon: faCalendarAlt, component: "Appointments" },
  { name: "Responses", icon: faComments, component: "Responses" },
  { name: "Treatment", icon: faTasks, component: "Treatments" },
  { name: "Post Recovery", icon: faRecycle, component: "PostRecovery" },
  { name: "Assessments", icon: faFileAlt, component: "Assessments" },
  { name: "Service Providers", icon: faMapMarkerAlt, component: "ResourceCenters" },
];

// Define sub-menu icons for Financials
const financialSubMenuIcons = [{ name: "Billing", icon: faDollarSign, component: "Billing" }];



// Handle navigation back to main menu
const handleBackToMainMenu = () => {
  setSelectedComponent(null);
  setSubMenu(null);
  setMainMenu(true);
  setBreadcrumbTitle("Home");
};

// Handle click on main menu or sub-menu icons
const handleMainMenuClick = (menu) => {
  if (menu === "Logout") {
    handleLogout();
  } else if (menu === "therapy" || menu === "financials" || menu === "administration") {
    setSubMenu(menu);
    setMainMenu(false);
    setBreadcrumbTitle(menu.charAt(0).toUpperCase() + menu.slice(1)); // Set breadcrumb title dynamically
  } else {
    setSelectedComponent(menu);
    setMainMenu(false);
    setBreadcrumbTitle(menu); // Set the breadcrumb to component name
  }
};


  // Render icons based on whether we are in main menu or sub-menu
  const renderIcons = (icons) => (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 4 }}>
      {icons.map((icon, index) => (
        <Box key={index} sx={{ textAlign: "center" }}>
          <IconButton
            onClick={() =>
              icon.subMenu
                ? handleMainMenuClick(icon.subMenu)
                : setSelectedComponent(icon.component)
            }
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={icon.icon} />
          </IconButton>
          <Typography variant="body2" sx={{fontWeight:'bold'}}>{icon.name}</Typography>
        </Box>
      ))}
    </Box>
  );

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Appointments':
        return <UserAppointment />;
      case 'Responses':
        return <UserAssessment />;
      case 'Billing':
        return <UserSubscriptions />;
      case 'Sessions':
        return <UserSessions />;
      case 'Treatments':
        return <UserTreatments />;
      case 'PostRecovery':
        return <UserPostRecovery />;
      case 'Assessments':
        return <UserAssessment />;
      case 'Feedback':
        return <UserAssessment />;
      case 'OurShop':
        return <UserAssessment />;
      case 'Calendar':
        return <UserAssessment />;
      case 'Map':
        return <UserAssessment />;
      case 'Chat':
        return <UserAssessment />;
      case 'Help':
        return <UserAssessment />;
      case 'Settings':
        return <UserSettings />;
      default:
        return mainMenu ? renderIcons(mainMenuIcons) : null;
    }
  };

  return (
    <Container sx={{ mt: 8, mb: 4 }}>
      {/* Display a greeting message */}
      <Typography variant="h4" gutterBottom>
        Hello, {user ? user.name : 'Guest'}!
      </Typography>
      {/* Breadcrumbs */}
      <Breadcrumbs aria-label="breadcrumb" sx={{color:'#2C1C18'}}>
        <Link underline="hover" color="inherit" onClick={handleBackToMainMenu} style={{ cursor: "pointer" }}>
          Home
        </Link>
        {subMenu && !selectedComponent && <Typography color="text.primary">{breadcrumbTitle}</Typography>}
        {selectedComponent && <Typography color="text.primary">{breadcrumbTitle}</Typography>}
      </Breadcrumbs>

      {/* Render Main Menu or Sub Menu */}
      {mainMenu && !selectedComponent && renderIcons(mainMenuIcons)}

      {/* Sub Menu Rendering */}
      {!mainMenu && !selectedComponent && subMenu === "therapy" && (
        <>
          {renderIcons(therapySubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}
      {!mainMenu && !selectedComponent && subMenu === "financials" && (
        <>
          {renderIcons(financialSubMenuIcons)}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </>
      )}

      {/* Render Selected Component */}
      {selectedComponent && (
        <Box sx={{ mt: 4, mb: 4 }}>
          {renderComponent()}
          <Box sx={{ textAlign: "center", my: 4 }}>
            <IconButton onClick={handleBackToMainMenu} sx={{ fontSize: 40, color: "primary.main" }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Centered Logout Button */}
      {mainMenu && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <IconButton
            onClick={handleLogout}
            sx={{
              fontSize: 40,
              color: "primary.main",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.2)", color: "secondary.main" },
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
          </IconButton>
          <Typography color="#F9A64F" variant="body2" sx={{fontWeight:"bold"}}>Logout</Typography>
        </Box>
      )}

      {/* Snackbar for feedback messages */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ open: false })}>
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default UserInbox;
