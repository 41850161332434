import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  NoteAdd as NoteAddIcon,
  Publish as PublishIcon
} from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const SuperuserBlogPosts = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [blogPosts, setBlogPosts] = useState([]);
  const [filteredBlogPosts, setFilteredBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBlogPost, setSelectedBlogPost] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formData, setFormData] = useState({ title: "", author: "", content: "", category: "", image: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const fetchBlogPosts = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/blogposts");
      setBlogPosts(response.data);
      setFilteredBlogPosts(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch blog posts data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredBlogPosts(
      blogPosts.filter(
        (post) =>
          post.title.toLowerCase().includes(value) ||
          post.author.toLowerCase().includes(value)
      )
    );
  };

  const handleEditIconClick = (post) => {
    setSelectedBlogPost(post);
    setFormData(post);
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (post) => {
    setSelectedBlogPost(post);
    setOpenDeleteModal(true);
  };

  const handlePublishIconClick = (post) => {
    // Implement publish functionality here
    setSnackbar({ open: true, message: "Blog post published successfully", severity: "success" });
  };

  const handleAddIconClick = () => {
    setFormData({ title: "", author: "", content: "", category: "", image: "" });
    setOpenAddModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/blogposts/${selectedBlogPost.id}`, formData);
      fetchBlogPosts();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Blog post updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update blog post data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://api.cope.ke/blogposts/${selectedBlogPost.id}`);
      fetchBlogPosts();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Blog post deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete blog post data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/blogposts", formData);
      fetchBlogPosts();
      setOpenAddModal(false);
      setSnackbar({ open: true, message: "Blog post added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add blog post", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredBlogPosts.map((post) => (
        <Card key={post.id} sx={{ width: 300, m: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar src={post.image || ''}>
                {!post.image && <FontAwesomeIcon icon={faUser} size="2x" />}
              </Avatar>
              <Box>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEditIconClick(post)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDeleteIconClick(post)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Publish">
                  <IconButton onClick={() => handlePublishIconClick(post)}>
                    <PublishIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{post.title}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faUser} /> {post.author}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faEdit} /> {post.content}
            </Typography>
            <Typography variant="body2">
              <Chip label={post.category} color="primary" />
            </Typography>
            <Typography variant="body2">
              {new Date(post.date).toLocaleDateString()}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Blog Posts</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search blog posts"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<NoteAddIcon />}
          onClick={handleAddIconClick}
          sx={{ padding: isMobile ? '8px 12px' : '8px 16px' }}
        >
          Add Blog Post
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredBlogPosts.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No blog posts found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Edit Blog Post</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            value={formData.title || ""}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="author"
            label="Author"
            name="author"
            value={formData.author || ""}
            onChange={(e) => setFormData({ ...formData, author: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="content"
            label="Content"
            name="content"
            multiline
            rows={4}
            value={formData.content || ""}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="category"
            label="Category"
            name="category"
            value={formData.category || ""}
            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          />
          <TextField
            margin="normal"
            fullWidth
            id="image"
            label="Image URL"
            name="image"
            value={formData.image || ""}
            onChange={(e) => setFormData({ ...formData, image: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAddSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Blog Post</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            value={formData.title || ""}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="author"
            label="Author"
            name="author"
            value={formData.author || ""}
            onChange={(e) => setFormData({ ...formData, author: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="content"
            label="Content"
            name="content"
            multiline
            rows={4}
            value={formData.content || ""}
            onChange={(e) => setFormData({ ...formData, content: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="category"
            label="Category"
            name="category"
            value={formData.category || ""}
            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          />
          <TextField
            margin="normal"
            fullWidth
            id="image"
            label="Image URL"
            name="image"
            value={formData.image || ""}
            onChange={(e) => setFormData({ ...formData, image: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this blog post?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserBlogPosts;
