import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import IconMarquee from "./IconMarquee";
import Navbar from "./Navbar";
import SocialMediaLinks from "./SocialMediaLinks";


import AdminMenu from "./AdminMenu";

import SuperuserTherapyPostRecovery from "./SuperuserTherapyPostRecovery";
import SuperuserTherapyAssessments from "./SuperuserTherapyAssessments";

const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];



const ViewSuperuserTherapyAssessments = () => {


  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleClientsViewClick = () => {
    navigate('/clients');
};

  const handleBillingViewClick = () => {
    navigate('/billing');
};

  const handleDashboardViewClick = () => {
    navigate('/admin-profile');
};

const handleQuestionaireViewClick = () => {
    navigate('/questionnaire');
};

const handleAccountsViewClick = () => {
  navigate('/user-accounts');
};

const handlePostRecoveryViewClick = () => {
  navigate('/post-recovery');
};

const handleSubscriptionsViewClick = () => {
  navigate('/subscriptions');
};

const handleProfileViewClick = () => {
  navigate('/settings');
};

const handleAppointmentsViewClick = () => {
    navigate('/appointments');
  };

  const handleAssessmentsViewClick = () => {
    navigate('/assessments');
  }; 

  const handleSessionsViewClick = () => {
    navigate('/sessions');
  };

  const handleTreatmentsViewClick = () => {
    navigate('/treatment');
  };

  const handleTicketsViewClick = () => {
    navigate('/tickets');
  };
  
  const handleResourceCentersViewClick = () => {
    navigate('/resource-centers');
  };

  const handleBlogPostsViewClick = () => {
    navigate('/blogposts');
  };

  const handleCalendarsViewClick = () => {
    navigate('/calendars');
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Navbar />
      <Paper
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          marginTop: `${navbarHeight}px`,
          padding: 20,
          backgroundColor: "#1B277D",
          color: "#FFF",
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex", height: "calc(100vh - 64px)" }}>
          <Box
            sx={{
              width: { xs: 0, sm: "200px" }, // Adjust width as needed
              overflowY: "auto",
            }}
          >
            {/* Dashboard and TreeView go here */}
            <Typography variant="h6" style={{ padding: "16px" }}>
              Dashboard
            </Typography>
            {/* TreeView component */}
            <AdminMenu handleClientsViewClick={handleClientsViewClick} handleCalendarsViewClick={handleCalendarsViewClick} handleBlogPostsViewClick={handleBlogPostsViewClick} handleResourceCentersViewClick={handleResourceCentersViewClick} handleTicketsViewClick={handleTicketsViewClick} handleTreatmentsViewClick={handleTreatmentsViewClick} handleSessionsViewClick={handleSessionsViewClick} handleAssessmentsViewClick={handleAssessmentsViewClick} handleAppointmentsViewClick={handleAppointmentsViewClick}  handleProfileViewClick={handleProfileViewClick} handleSubscriptionsViewClick={handleSubscriptionsViewClick} handlePostRecoveryViewClick={handlePostRecoveryViewClick} handleAccountsViewClick={handleAccountsViewClick} handleQuestionaireViewClick={handleQuestionaireViewClick} handleBillingViewClick={handleBillingViewClick} handleDashboardViewClick={handleDashboardViewClick}   />

          </Box>
          <Box sx={{ flex: 1, overflowY: "auto" }}>
            {/* Main content goes here */}
            <Paper
              elevation={3}
              style={{
                flexGrow: 1,
                overflow: "auto",
                height: "calc(100vh - 180px - 32px)",
              }}
            >
              <SuperuserTherapyAssessments />
            </Paper>
          </Box>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="caption" style={{ color: "#00e5ff" }}>
              All Rights Reserved
            </Typography>
            <Typography style={{ color: "#fff" }}>
              © {new Date().getFullYear()} Macrobian Health
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ViewSuperuserTherapyAssessments;
