import React, { useState } from 'react';
import { Box, Button, Tooltip, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';

const WebAccessibility = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDarkContrast, setDarkContrast] = useState(false);
  const [isInvertedColors, setInvertedColors] = useState(false);
  const [isHighlightedLinks, setHighlightedLinks] = useState(false);
  const [isBiggerText, setBiggerText] = useState(false);
  const [isPausedAnimations, setPausedAnimations] = useState(false);
  const [isLegibleFonts, setLegibleFonts] = useState(false);
  const [isBigCursor, setBigCursor] = useState(false);

  // Menu Handling
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Invert Colors
  const toggleInvertColors = () => {
    setInvertedColors((prev) => !prev);
    document.body.classList.toggle('invert-colors');
  };

  // Dark Contrast
  const toggleDarkContrast = () => {
    setDarkContrast((prev) => !prev);
    document.body.classList.toggle('dark-contrast');
  };

  // Highlight Links
  const toggleHighlightLinks = () => {
    setHighlightedLinks((prev) => !prev);
    document.body.classList.toggle('highlight-links');
  };

  // Bigger Text
  const toggleBiggerText = () => {
    setBiggerText((prev) => !prev);
    document.body.classList.toggle('bigger-text');
  };

  // Pause Animations
  const togglePauseAnimations = () => {
    setPausedAnimations((prev) => !prev);
    document.body.classList.toggle('pause-animations');
  };

  // Legible Fonts
  const toggleLegibleFonts = () => {
    setLegibleFonts((prev) => !prev);
    document.body.classList.toggle('legible-fonts');
  };

  // Big Cursor
  const toggleBigCursor = () => {
    setBigCursor((prev) => !prev);
    document.body.classList.toggle('big-cursor');
  };

  // Reset All
  const resetAll = () => {
    document.body.classList.remove(
      'invert-colors',
      'dark-contrast',
      'highlight-links',
      'bigger-text',
      'pause-animations',
      'legible-fonts',
      'big-cursor'
    );
    setInvertedColors(false);
    setDarkContrast(false);
    setHighlightedLinks(false);
    setBiggerText(false);
    setPausedAnimations(false);
    setLegibleFonts(false);
    setBigCursor(false);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        right: 20,
        bottom: 150,
        zIndex: 1000,
      }}
    >
      <Tooltip title="Web Accessibility Options">
        <IconButton
          onClick={handleMenuOpen}
          sx={{
            backgroundColor: '#fdbf57',
            color: '#000',
            '&:hover': {
              backgroundColor: '#e46e24',
              color: '#fff',
            },
          }}
        >
          <AccessibilityNewIcon />
        </IconButton>
      </Tooltip>

      {/* Accessibility Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{ zIndex: 1300 }}
      >
        <MenuItem onClick={toggleInvertColors}>
          {isInvertedColors ? 'Disable Invert Colors' : 'Invert Colors'}
        </MenuItem>
        <MenuItem onClick={toggleDarkContrast}>
          {isDarkContrast ? 'Disable Dark Contrast' : 'Dark Contrast'}
        </MenuItem>
        <MenuItem onClick={toggleHighlightLinks}>
          {isHighlightedLinks ? 'Disable Highlight Links' : 'Highlight Links'}
        </MenuItem>
        <MenuItem onClick={toggleBiggerText}>
          {isBiggerText ? 'Disable Bigger Text' : 'Bigger Text'}
        </MenuItem>
        <MenuItem onClick={togglePauseAnimations}>
          {isPausedAnimations ? 'Resume Animations' : 'Pause Animations'}
        </MenuItem>
        <MenuItem onClick={toggleLegibleFonts}>
          {isLegibleFonts ? 'Disable Legible Fonts' : 'Legible Fonts'}
        </MenuItem>
        <MenuItem onClick={toggleBigCursor}>
          {isBigCursor ? 'Disable Big Cursor' : 'Big Cursor'}
        </MenuItem>
        <MenuItem onClick={resetAll}>
          <Typography sx={{ color: '#f00' }}>Reset All Features</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default WebAccessibility;
