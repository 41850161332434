import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Tooltip,
  Avatar,
  useMediaQuery,
  useTheme,
  Fab,
} from "@mui/material";
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  NoteAdd as NoteAddIcon,
  ArrowBack as ArrowBackIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Info as InfoIcon,
  AddLocation as AddLocationIcon,
  Category as CategoryIcon,
  Upload as UploadIcon
} from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospital, faMapMarkerAlt, faPhone, faStethoscope } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const SuperuserResourceCenters = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [resourceCenters, setResourceCenters] = useState([]);
  const [filteredResourceCenters, setFilteredResourceCenters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedResourceCenter, setSelectedResourceCenter] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openSpecializationModal, setOpenSpecializationModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openLogoModal, setOpenLogoModal] = useState(false);
  const [formData, setFormData] = useState({
    center: "",
    location: "",
    tel: "",
    specialization: "",
    lat: "",
    lng: ""
  });
  const [locationData, setLocationData] = useState({ location: "", lat: "", lng: "" });
  const [specializationData, setSpecializationData] = useState({ specialization: "" });
  const [logoData, setLogoData] = useState({ logo: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchResourceCenters();
  }, []);

  const fetchResourceCenters = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/api/resource-centres");
      setResourceCenters(response.data);
      setFilteredResourceCenters(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch resource centers data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredResourceCenters(
      resourceCenters.filter(
        (center) =>
          center.center.toLowerCase().includes(value) ||
          center.location.toLowerCase().includes(value)
      )
    );
  };

  const handleCardClick = (center) => {
    setSelectedResourceCenter(center);
  };

  const handleEditIconClick = (center, e) => {
    e.stopPropagation();
    setSelectedResourceCenter(center);
    setFormData(center);
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (center, e) => {
    e.stopPropagation();
    setSelectedResourceCenter(center);
    setOpenDeleteModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/api/resource-centres/${selectedResourceCenter.id}`, formData);
      fetchResourceCenters();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Resource center updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update resource center data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://api.cope.ke/api/resource-centres/${selectedResourceCenter.id}`);
      fetchResourceCenters();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Resource center deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete resource center data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/api/resource-centres", formData);
      fetchResourceCenters();
      setOpenAddModal(false);
      setSnackbar({ open: true, message: "Resource center added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add resource center", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleLocationSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/api/resource-centres/${selectedResourceCenter.id}/location`, locationData);
      fetchResourceCenters();
      setOpenLocationModal(false);
      setSnackbar({ open: true, message: "Location updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update location data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleSpecializationSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/api/resource-centres/${selectedResourceCenter.id}/specialization`, specializationData);
      fetchResourceCenters();
      setOpenSpecializationModal(false);
      setSnackbar({ open: true, message: "Specialization updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update specialization data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleLogoSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/api/resource-centres/${selectedResourceCenter.id}/logo`, logoData);
      fetchResourceCenters();
      setOpenLogoModal(false);
      setSnackbar({ open: true, message: "Logo updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update logo", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderResourceCenterCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredResourceCenters.map((center) => (
        <Card key={center.id} sx={{ width: 300, m: 2, cursor: "pointer", transition: "transform 0.3s", "&:hover": { transform: "scale(1.05)" } }} onClick={() => handleCardClick(center)}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar sx={{ position: "relative" }}>
                <IconButton
                  sx={{ position: "absolute", right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                  onClick={(e) => { e.stopPropagation(); setSelectedResourceCenter(center); setOpenLogoModal(true); }}
                >
                  <UploadIcon fontSize="small" />
                </IconButton>
              </Avatar>
              <Box>
                <Tooltip title="Edit">
                  <IconButton onClick={(e) => { e.stopPropagation(); handleEditIconClick(center, e); }} style={{ color: 'blue' }}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteIconClick(center, e); }} style={{ color: 'red' }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Location">
                  <IconButton onClick={(e) => { e.stopPropagation(); setOpenLocationModal(true); }} style={{ color: 'green' }}>
                    <AddLocationIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add Specialization">
                  <IconButton onClick={(e) => { e.stopPropagation(); setOpenSpecializationModal(true); }} style={{ color: 'purple' }}>
                    <CategoryIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{center.center}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faMapMarkerAlt} /> {center.location}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faPhone} /> {center.tel}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faStethoscope} /> {center.specialization}
            </Typography>
            <Typography variant="body2">
              Status:{" "}
              {center.status === "inactive" ? (
                <Chip label="Inactive" color="error" />
              ) : (
                <Chip label="Active" color="success" />
              )}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography sx={{color:"#00695C" }} variant="h4" gutterBottom>
        {selectedResourceCenter ? `Resources at ${selectedResourceCenter.center}` : "Therapy Resource Centers"}
        <Tooltip title="Info">
          <IconButton onClick={() => setOpenInfoModal(true)} style={{ color: 'blue' }}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <Tooltip title="Go Back">
          <IconButton onClick={() => setSelectedResourceCenter(null)} disabled={!selectedResourceCenter}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<NoteAddIcon />}
          onClick={() => setOpenAddModal(true)}
          sx={{ padding: isMobile ? '8px 12px' : '8px 16px' }}
        >
          {selectedResourceCenter ? "Add Resource" : "Add"}
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredResourceCenters.length > 0 ? (
        renderResourceCenterCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No resource centers found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Edit Resource Center</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="center"
            label="Name"
            name="center"
            value={formData.center || ""}
            onChange={(e) => setFormData({ ...formData, center: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="tel"
            label="Contact"
            name="tel"
            value={formData.tel || ""}
            onChange={(e) => setFormData({ ...formData, tel: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAddSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add {selectedResourceCenter ? "Resource" : "Resource Center"}</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="center"
            label="Name"
            name="center"
            value={formData.center || ""}
            onChange={(e) => setFormData({ ...formData, center: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="tel"
            label="Phone"
            name="tel"
            value={formData.tel || ""}
            onChange={(e) => setFormData({ ...formData, tel: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openLocationModal} onClose={() => setOpenLocationModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleLocationSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Update Location</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="location"
            label="Location"
            name="location"
            value={locationData.location || ""}
            onChange={(e) => setLocationData({ ...locationData, location: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lat"
            label="Latitude"
            name="lat"
            value={locationData.lat || ""}
            onChange={(e) => setLocationData({ ...locationData, lat: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lng"
            label="Longitude"
            name="lng"
            value={locationData.lng || ""}
            onChange={(e) => setLocationData({ ...locationData, lng: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openSpecializationModal} onClose={() => setOpenSpecializationModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleSpecializationSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Update Specialization</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="specialization"
            label="Specialization"
            name="specialization"
            value={specializationData.specialization || ""}
            onChange={(e) => setSpecializationData({ ...specializationData, specialization: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openLogoModal} onClose={() => setOpenLogoModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleLogoSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Upload Logo</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="logo"
            label="Logo URL"
            name="logo"
            value={logoData.logo || ""}
            onChange={(e) => setLogoData({ ...logoData, logo: e.target.value })}
            InputLabelProps={{ style: { color: '#2C1C18' } }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="delete-modal-title" variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography id="delete-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this resource center?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openInfoModal}
        onClose={() => setOpenInfoModal(false)}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="info-modal-title" variant="h6" component="h2">
            How to Use the Resource Centers Screen
          </Typography>
          <Typography id="info-modal-description" sx={{ mt: 2 }}>
            <ul>
              <li>Click on a resource center card to view its resources.</li>
              <li>Use the icons on the resource center card to edit, delete, add location, and add specialization.</li>
              <li>Use the search bar to filter resource centers and resources.</li>
            </ul>
          </Typography>
          <Button onClick={() => setOpenInfoModal(false)} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Tooltip title="Return to Top">
        <Fab
          color="primary"
          onClick={handleScrollToTop}
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserResourceCenters;
