import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Button,
  Snackbar,
  Alert,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  CircularProgress,
  Modal,
  InputAdornment,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Info as InfoIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import axios from 'axios';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const defaultCenter = { lat: 1.2921, lng: 36.8219 }; // Default location

const SuperuserMap = ({ userLocation }) => {
  const [resourceCenters, setResourceCenters] = useState([]);
  const [filteredResourceCenters, setFilteredResourceCenters] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(userLocation || null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchResourceCenters();
    // Check for geolocation support
    if (!userLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude }),
        (error) => console.error('Error getting user location:', error)
      );
    } else if (userLocation) {
      setCurrentLocation(userLocation);
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, [userLocation]);

  const fetchResourceCenters = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/api/resource-centres");
      setResourceCenters(response.data);
      setFilteredResourceCenters(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch resource centers data", severity: "error" });
      setLoading(false);
    }
  };

  const handleMarkerClick = (resource) => {
    setSelectedResource(resource);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredResourceCenters(
      resourceCenters.filter(
        (center) =>
          center.center.toLowerCase().includes(value) ||
          center.location.toLowerCase().includes(value)
      )
    );
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <Tooltip title="Go Back">
          <IconButton onClick={() => window.history.back()} disabled={loading}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h4" gutterBottom sx={{ flexGrow: 1 }}>
          Resource Centers Map
        </Typography>
        <Tooltip title="Info">
          <IconButton onClick={() => setOpenInfoModal(true)} style={{ color: 'blue' }}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search Resource Centers"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mr: 2 }}
        />
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <LoadScript googleMapsApiKey="AIzaSyCP-eSkk0KC2cC8BWvjtBFZY66DztBTvRo">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={currentLocation || defaultCenter}
            zoom={10}
          >
            {currentLocation && <Marker position={currentLocation} />}
            {filteredResourceCenters.map((resource, index) => (
              <Marker
                key={index}
                position={{ lat: resource.latitude, lng: resource.longitude }}
                onClick={() => handleMarkerClick(resource)}
              />
            ))}
            {selectedResource && (
              <InfoWindow
                position={{ lat: selectedResource.latitude, lng: selectedResource.longitude }}
                onCloseClick={() => setSelectedResource(null)}
              >
                <div>
                  <h3>{selectedResource.center}</h3>
                  <p>{selectedResource.description}</p>
                  {/* Add more details as needed */}
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        </LoadScript>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {/* Info Modal */}
      <Modal
        open={openInfoModal}
        onClose={() => setOpenInfoModal(false)}
        aria-labelledby="info-modal-title"
        aria-describedby="info-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="info-modal-title" variant="h6" component="h2">
            How to Use the Resource Centers Map
          </Typography>
          <Typography id="info-modal-description" sx={{ mt: 2 }}>
            <ul>
              <li>Click on a marker to view the details of a resource center.</li>
              <li>Use the search bar to filter resource centers on the map.</li>
              <li>Click on the info icon for additional help and instructions.</li>
            </ul>
          </Typography>
          <Button onClick={() => setOpenInfoModal(false)} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserMap;
