import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme,
  Fab,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from "@mui/material";
import {
  Search as SearchIcon,
  NoteAdd as NoteAddIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Event as EventIcon,
  ArrowBack as ArrowBackIcon,
  LocalHospital as LocalHospitalIcon,
  Info as InfoIcon,
  Add as AddIcon,
  Upload as UploadIcon
} from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { addDays, format } from 'date-fns';

const SuperuserAppointments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openReferralModal, setOpenReferralModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [notes, setNotes] = useState("");
  const [appointmentDate, setAppointmentDate] = useState(format(addDays(new Date(), 1), 'yyyy-MM-dd'));
  const [appointmentTime, setAppointmentTime] = useState("09:00");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [providers, setProviders] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchAppointments();
    fetchProviders();
  }, []);

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.cope.ke/appointments");
      const appointmentsWithDetails = await Promise.all(response.data.map(async (appointment) => {
        const userDetails = await fetchUserDetails(appointment.user_id);
        return { ...appointment, ...userDetails };
      }));
      setAppointments(appointmentsWithDetails);
      setFilteredAppointments(appointmentsWithDetails);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch appointments data", severity: "error" });
      setLoading(false);
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axios.get(`https://api.cope.ke/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch user details for user_id ${userId}`, error);
      return { name: "Unknown", email: "Unknown" };
    }
  };

  const fetchProviders = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/service_providers");
      setProviders(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch providers data", severity: "error" });
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAppointments(
      appointments.filter(
        (appointment) =>
          appointment.name.toLowerCase().includes(value) ||
          appointment.email.toLowerCase().includes(value)
      )
    );
  };

  const handleNotesIconClick = (event, appointment) => {
    event.stopPropagation();
    setFormData(appointment);
    setOpenNotesModal(true);
  };

  const handleAppointmentIconClick = (event, appointment) => {
    event.stopPropagation();
    setFormData(appointment);
    setAppointmentDate(format(addDays(new Date(), 1), 'yyyy-MM-dd'));
    setOpenAppointmentModal(true);
  };

  const handleReferralIconClick = (event, appointment) => {
    event.stopPropagation();
    setFormData(appointment);
    setOpenReferralModal(true);
  };

  const handleInfoClick = () => {
    setOpenInfoModal(true);
  };

  const handleNotesSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`https://api.cope.ke/book_appointment/${formData.appointment_id}/notes`, { notes });
      fetchAppointments();
      setOpenNotesModal(false);
      setSnackbar({ open: true, message: "Notes added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add notes", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAppointmentSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/book_appointment", { 
        user_id: formData.user_id, 
        provider_id: selectedProvider, 
        appointment_date: appointmentDate,
        appointment_time: appointmentTime,
        email: formData.email
      });
      fetchAppointments();
      setOpenAppointmentModal(false);
      setSnackbar({ open: true, message: "Appointment booked successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to book appointment", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredAppointments.map((appointment) => (
        <Card key={appointment.appointment_id} sx={{ width: 300, m: 2, cursor: "pointer", transition: "transform 0.3s", "&:hover": { transform: "scale(1.05)" } }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar>
                <FontAwesomeIcon icon={faUser} size="2x" />
              </Avatar>
              <Box>
                <Tooltip title="Add Notes">
                  <IconButton onClick={(e) => handleNotesIconClick(e, appointment)} sx={{ color: "green" }}>
                    <NoteAddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Book Appointment">
                  <IconButton onClick={(e) => handleAppointmentIconClick(e, appointment)} sx={{ color: "blue" }}>
                    <EventIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Referral">
                  <IconButton onClick={(e) => handleReferralIconClick(e, appointment)} sx={{ color: "purple" }}>
                    <LocalHospitalIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{appointment.name}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faEnvelope} /> {appointment.email}
            </Typography>
            <Typography variant="body2">
              Appointment Date: {new Date(appointment.appointment_date).toLocaleDateString()}
            </Typography>
            <Typography variant="body2">
              Appointment Time: {appointment.appointment_time || "Not Set"}
            </Typography>
            <Typography variant="body2">
              Status:{" "}
              {appointment.status === "Pending" ? (
                <Chip label="Pending" color="warning" />
              ) : appointment.status === "Completed" ? (
                <Chip label="Completed" color="success" />
              ) : (
                <Chip label="Cancelled" color="error" />
              )}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>Appointments</Typography>
        <Tooltip title="How to use">
          <IconButton onClick={handleInfoClick}>
            <InfoIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Tooltip title="Back">
          <IconButton>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search appointments"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ flexGrow: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setFormData({ username: "", email: "", phone: "", avatar: "" });
            setOpenAddModal(true);
          }}
          sx={{ padding: isMobile ? '8px 12px' : '8px 16px' }}
        >
          Add Appointment
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredAppointments.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No appointments found.
        </Typography>
      )}

      <Modal open={openNotesModal} onClose={() => setOpenNotesModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleNotesSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Add Notes</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="date"
            label="Date"
            name="date"
            type="date"
            value={formData.date || new Date().toISOString().split('T')[0]}
            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="notes"
            label="Notes"
            name="notes"
            value={formData.notes || ""}
            onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openAppointmentModal} onClose={() => setOpenAppointmentModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAppointmentSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Book Appointment</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="appointmentDate"
            label="Appointment Date"
            name="appointmentDate"
            type="date"
            value={appointmentDate}
            onChange={(e) => setAppointmentDate(e.target.value)}
            InputProps={{ inputProps: { min: format(addDays(new Date(), 1), 'yyyy-MM-dd') }}}  // Disable past dates
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="appointmentTime"
            label="Appointment Time"
            name="appointmentTime"
            type="time"
            value={appointmentTime}
            onChange={(e) => setAppointmentTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="provider-select-label">Service Provider</InputLabel>
            <Select
              labelId="provider-select-label"
              id="provider-select"
              value={selectedProvider}
              label="Service Provider"
              onChange={(e) => setSelectedProvider(e.target.value)}
            >
              {providers.map((provider) => (
                <MenuItem key={provider.provider_id} value={provider.provider_id}>
                  {provider.company_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openInfoModal} onClose={() => setOpenInfoModal(false)}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>How to Use Appointments Screen</Typography>
          <Typography variant="body1" gutterBottom>
            <ul>
              <li>Click on a client card to view appointment details.</li>
              <li>Use the icons on the client card to add notes, book an appointment, or refer a client.</li>
              <li>Click on the information icon to view this help modal.</li>
            </ul>
          </Typography>
          <Button onClick={() => setOpenInfoModal(false)} color="primary">
            OK
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Tooltip title="Return to Top">
        <Fab
          color="primary"
          onClick={handleScrollToTop}
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserAppointments;
