import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  InputAdornment
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon
} from "@mui/icons-material";
import axios from "axios";

const UserSessions = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchSessions();
  }, []);

  const fetchSessions = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/sessions");
      setSessions(response.data);
      setFilteredSessions(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch sessions", error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredSessions(
      sessions.filter(session =>
        session.provider.toLowerCase().includes(value) ||
        session.status.toLowerCase().includes(value)
      )
    );
  };

  const handleRowClick = (session) => {
    setSelectedSession(session);
  };

  const handleBackClick = () => {
    setSelectedSession(null);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSessions.length > 0 ? (
            filteredSessions.map((session) => (
              <TableRow key={session.id} onClick={() => handleRowClick(session)}>
                <TableCell>{new Date(session.date).toLocaleDateString()}</TableCell>
                <TableCell>{session.provider}</TableCell>
                <TableCell>{session.status}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleRowClick(session)}>
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No sessions available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Session Details</Typography>
      <Typography variant="body1"><strong>Date:</strong> {new Date(selectedSession.date).toLocaleDateString()}</Typography>
      <Typography variant="body1"><strong>Provider:</strong> {selectedSession.provider}</Typography>
      <Typography variant="body1"><strong>Status:</strong> {selectedSession.status}</Typography>
      <Typography variant="body1"><strong>Details:</strong> {selectedSession.details}</Typography>
      <Button variant="contained" color="primary" sx={{ mt: 2 }}>
        Your Feedback
      </Button>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Your Sessions</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search sessions"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedSession ? (
        renderDetails()
      ) : (
        renderTable()
      )}
    </Container>
  );
};

export default UserSessions;
