import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Box,  Grid, Typography, Button, TextField, CircularProgress, Modal, Snackbar, Alert, Table, TableBody, TableCell, TableRow, TableHead, TableContainer, Paper, IconButton, TimelineContainer } from '@mui/material';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/CheckCircle';
import FlagIcon from '@mui/icons-material/Flag';
import PuzzleIcon from '@mui/icons-material/Extension';
import RocketIcon from '@mui/icons-material/RocketLaunch';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import WebAccessibility from '../components/WebAccessibility';
import ScrollTopFab from '../components/ScrollTopFab';

import serviceImage from '../assets/audience.jpg';
import therapistImage from '../assets/image.jpg';
import therapyImage1 from '../assets/session1.jpg';
import therapyImage2 from '../assets/session2.jpg';
import therapyImage3 from '../assets/session3.jpg';

// Styling
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const steps = [
  {
    title: 'It all starts with you.',
    description: 'You sign up on Cope, sharing a little about yourself and your mental health needs. From there, we take care of the rest.',
    icon: <FlagIcon sx={{ fontSize: '60px', color: '#fff' }} />,
  },
  {
    title: 'Next, we find your match.',
    description: 'Using the information you’ve provided, Cope connects you with a therapist who specializes in the areas you’re seeking help with.',
    icon: <PuzzleIcon sx={{ fontSize: '60px', color: '#fff' }} />,
  },
  {
    title: 'It’s time to take the first step...',
    description: 'You can easily book a session, choosing between an in-person meeting or the comfort of an online session here.',
    icon: <RocketIcon sx={{ fontSize: '60px', color: '#fff' }} />,
  },
  {
    title: 'And it doesn’t stop there...',
    description: 'After each session, you’ll receive therapist notes, personalized recommendations, and exercises to track your progress as you grow.',
    icon: <CheckIcon sx={{ fontSize: '60px', color: '#fff' }} />,
  },
];


// Main component function
const Homepage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '' });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [openTherapistModal, setOpenTherapistModal] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [openBookingModal, setOpenBookingModal] = useState(false);
  const [selectedTherapistId, setSelectedTherapistId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenTherapistModal = () => setOpenTherapistModal(true);
  const handleCloseTherapistModal = () => setOpenTherapistModal(false);
  const handleBookClick = (therapistId) => {
    setSelectedTherapistId(therapistId);
    setOpenBookingModal(true);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.post('https://api.cope.ke/book_appointment', formData);
      setSnackbar({ open: true, message: 'Appointment booked successfully!', severity: 'success' });
      handleCloseModal();
    } catch (error) {
      setSnackbar({ open: true, message: 'Booking failed. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitBooking = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://api.cope.ke/therapy_appointment', {
        name: userName,
        email: userEmail,
        phone: userPhone,
        provider_id: selectedTherapistId, 
        
      });
      setSnackbar({ open: true, message: 'Appointment booked successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to book appointment', severity: 'error' });
    } finally {
      setLoading(false);
      setOpenBookingModal(false);
    }
  };

  // Handle search for therapists
  const handleSearch = async (keyword) => {
    setSearchKeyword(keyword);
    setLoading(true);
    setNoResults(false);

    try {
      const response = await axios.get('https://api.cope.ke/service_providers');
      const regex = new RegExp(keyword, 'i');
      const filteredResults = response.data.filter((therapist) =>
        regex.test(therapist.company_name) || regex.test(therapist.specialization)
      );
      if (filteredResults.length > 0) {
        setSearchResults(filteredResults);
      } else {
        setNoResults(true);
        setSearchResults([]);
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error occurred while searching for therapists', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  // Styling for the vertical timeline
const TimelineContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '50px 0',
  backgroundColor: '#034f3b',
  color: '#fff',
  textAlign: 'center',
}));

const VerticalLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  top: '0',
  width: '4px',
  height: '100%',
  backgroundColor: '#e46e24',
  [theme.breakpoints.down('md')]: {
    left: '10%',
  },
}));

const CircleDot = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  backgroundColor: '#e46e24',
  borderRadius: '50%',
  position: 'absolute',
  left: 'calc(50% - 10px)',
  [theme.breakpoints.down('md')]: {
    left: 'calc(10% - 10px)',
  },
}));

const StepBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '30px',
  backgroundColor: '#1a6051',
  color: '#fff',
  width: '70%',
  borderRadius: '8px',
  textAlign: 'center',
  marginBottom: '30px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
  },
}));


  return (
    <HelmetProvider>
      <Helmet>
        <title>Your Mental Wellness Journey Starts Here - Cope</title>
        <meta name="description" content="Cope is an online platform providing mental health services for individuals, couples, and children. Get started on your journey to wellness today." />
      </Helmet>
      <Navbar />
      <Hero />

      {/* Section for booking therapists */}
      <Grid container spacing={2} sx={{ textAlign: 'center', padding: '50px 20px', backgroundColor: '#fdbf57' }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Take the First Step to Wellness Today.</Typography>
          <Typography variant="body1" sx={{ marginBottom: '20px', fontSize: '18px' }}>
            Don't wait. Start your journey to better mental health with a therapist who understands you. Tailored support is just a click away.
          </Typography>
          <Button onClick={handleOpenTherapistModal} variant="contained" sx={{ backgroundColor: '#e46e24', padding: '10px 20px' }}>
            Find a Therapist
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={serviceImage} alt="Service" style={{ width: '250px' }} />
        </Grid>
      </Grid>

      {/* How it works section with vertical timeline */}
      <TimelineContainer>
        <Typography variant="h4" sx={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '50px', color: '#fff' }}>
          How it works
        </Typography>

        <Grid container spacing={4} justifyContent="center" sx={{ position: 'relative' }}>
          <VerticalLine />
          {steps.map((step, index) => (
            <Grid item xs={12} md={6} key={index} sx={{ position: 'relative', display:'flex', justifyContent:'center',alignItems:'center' }}>
              <StepBox>
                {step.icon}
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#fdbf57' }}>
                  {step.title}
                </Typography>
                <Typography variant="body1" sx={{ color: '#fff', marginTop: '10px' }}>
                  {step.description}
                </Typography>
              </StepBox>
            </Grid>
          ))}
        </Grid>
      </TimelineContainer>

      {/* Therapy Image and Text Sections */}
      <Grid container spacing={4} sx={{ padding: '50px 20px', backgroundColor: '#fff' }}>
        <Grid item xs={12} md={5} sx={{ textAlign: 'center' }}>
          <img src={therapistImage} alt="Therapists" style={{ width: '100%', maxWidth: '350px' }} />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px', color: '#333' }}>
            With <span style={{ color: '#1a6051' }}>Cope</span>, Connect with Top Certified Professionals Ready to Guide Your Journey to Wellness
          </Typography>
          <Typography variant="body1" sx={{ color: '#666', marginBottom: '20px' }}>
            Access top-certified professionals committed to your well-being. Our expert therapists offer personalized support to help you make meaningful progress on your journey to wellness.
          </Typography>
          <Button  onClick={handleOpenModal} variant="contained" sx={{ backgroundColor: '#1a6051', color: '#fff', padding: '10px 20px', alignSelf:'center' }}>Get Therapy</Button>
        </Grid>
      </Grid>

      {/* Modal for Booking Appointment */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center', color: '#00695C' }}>Book an Appointment</Typography>
          <TextField required id="name" label="Your Name" name="name" InputLabelProps={{ style: { color: '#2C1C18' } }} value={formData.name} onChange={handleChange} fullWidth sx={{ marginBottom: 2 }} />
          <TextField required id="email" label="Your Email" name="email" InputLabelProps={{ style: { color: '#2C1C18' } }} value={formData.email} onChange={handleChange} type="email" fullWidth sx={{ marginBottom: 2 }} />
          <TextField label="Phone Number" name="phone" value={formData.phone} InputLabelProps={{ style: { color: '#2C1C18' } }} onChange={handleChange} fullWidth sx={{ marginBottom: '20px' }} />
          <Button variant="contained" sx={{ backgroundColor: '#e46e24', width: '100%', color: '#ffffff' }} onClick={handleSubmit} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Confirm Appointment'}
          </Button>
        </Box>
      </Modal>

      {/* Therapist Search Modal */}
      <Modal open={openTherapistModal} onClose={handleCloseTherapistModal}>
        <Box sx={modalStyle}>
          <Typography color='00695C' variant="h6" sx={{ mb: 2 }}>Search for a Therapist</Typography>
          <TextField  label="Search by name or specialization" InputLabelProps={{ style: { color: '#2C1C18' } }}  value={searchKeyword} onChange={(e) => handleSearch(e.target.value)} fullWidth sx={{ mb: 2 }} />
          {loading && <Box sx={{ textAlign: 'center', mt: 2 }}><CircularProgress /></Box>}
          {noResults && !loading && <Typography sx={{ mt: 2, textAlign: 'center', color: 'red' }}>No records found</Typography>}
          {searchResults.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', color: '#00695C' }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#00695C' }}>Specialization</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: '#00695C' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResults.map((therapist) => (
                    <TableRow key={therapist.provider_id}>
                      <TableCell>{therapist.company_name}</TableCell>
                      <TableCell>{therapist.specialization}</TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={() => handleBookClick(therapist.provider_id)}>Book</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Modal>

      {/* Appointment Booking Modal */}
      <Modal open={openBookingModal} onClose={() => setOpenBookingModal(false)}>
        <Box sx={modalStyle}>
          <IconButton onClick={() => setOpenBookingModal(false)} sx={{ position: 'absolute', top: 8, right: 8, color: '#00695C' }}><CloseIcon /></IconButton>
          <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center', color: '#00695C' }}>Book Appointment</Typography>
          <TextField label="Name" InputLabelProps={{ style: { color: '#2C1C18' } }} value={userName} onChange={(e) => setUserName(e.target.value)} fullWidth sx={{ marginBottom: '20px' }} />
          <TextField label="Email" InputLabelProps={{ style: { color: '#2C1C18' } }} value={userEmail} onChange={(e) => setUserEmail(e.target.value)} fullWidth sx={{ marginBottom: '20px' }} />
          <TextField label="Phone Number" InputLabelProps={{ style: { color: '#2C1C18' } }} value={userPhone} onChange={(e) => setUserPhone(e.target.value)} fullWidth sx={{ marginBottom: '20px' }} />
          <Button variant="contained" sx={{ backgroundColor: '#00695C', color: '#fff' }} onClick={handleSubmitBooking}>Book Appointment</Button>
        </Box>
      </Modal>

      {/* Snackbar for feedback */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>

      <Footer />
      <WebAccessibility />
      <ScrollTopFab />
    </HelmetProvider>
  );
};

export default Homepage;
