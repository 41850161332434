import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Card,
  CardContent,
  CardActionArea,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Navbar from "../components/Navbar";
import OurFeatures from "../components/OurFeatures";
import SocialMediaLinks from "../components/SocialMediaLinks";
import videoSource from "../assets/video.mp4";
import axios from "axios";

const Community = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navbarHeight = 64; // Adjust if your navbar's height is different
  const navigate = useNavigate();

  const [blogPosts, setBlogPosts] = useState([]);
  const [filteredBlogposts, setFilteredBlogposts] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);

  useEffect(() => {
    axios.get("https://api.cope.ke/blogposts")
      .then(response => {
        setBlogPosts(response.data);
        // Filter blog posts if needed
        setFilteredBlogposts(response.data.slice(0, 5)); // Example: taking only first 5 blog posts
      })
      .catch(error => {
        console.error("There was an error fetching the blog posts!", error);
      });
  }, []);

  const handleOpenDialog = (blog) => {
    setSelectedBlog(blog);
  };

  const handleCloseDialog = () => {
    setSelectedBlog(null);
  };

  const bannerStyle = {
    display: "flex",
    flexDirection: "column",
    marginTop: `${navbarHeight}px`,
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: 'url("https://source.unsplash.com/random?therapy")',
    position: "relative", // Needed for absolute positioning of the overlay
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)", // Adjust color and opacity here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "48px",
    position: "relative",
  };

  const subtitle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    fontSize: "28px",
    position: "relative",
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Navbar />
      <div style={bannerStyle}>
        <div style={overlayStyle}></div>
        <Typography variant="h3" style={textStyle}>
          Our Blog
        </Typography>
      </div>

      <Paper style={{ padding: 20, backgroundColor: "#e1f5fe", color: "#000" }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Our Blog</Typography>
        </Breadcrumbs>
      </Paper>

      <Box sx={{ padding: theme.spacing(2), backgroundColor: "#e1f5fe" }}>
        <Slider {...sliderSettings}>
          {blogPosts.map((post, index) => (
            <Card key={index} sx={{ margin: theme.spacing(1) }}>
              <CardActionArea>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {post.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {post.description}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ marginTop: 2 }}
                    onClick={() => navigate(`https://api.cope.ke/blogposts/${post.id}`)}
                  >
                    Read More
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Slider>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-around",
          bgcolor: "#e1f5fe",
          color: "#fff",
          overflow: "hidden",
        }}
      >
        {/* Video Player */}
        <Box
          flex={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: theme.spacing(2),
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ maxWidth: "60%", maxHeight: "100%" }} // Adjusted to use available space
          >
            <source src={videoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>

        {/* Blog Content */}
        <Box flex={2} sx={{ minWidth: 300, padding: theme.spacing(2) }}>
          <Typography variant="h6" color="#3f51b5" sx={{ mb: 2 }}>
            Most Popular This Week
          </Typography>
          {filteredBlogposts.map((blog, index) => (
            <Card key={index} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6" color="text.primary">
                  {blog.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  {blog.description}
                </Typography>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleOpenDialog(blog)}
                >
                  Learn More
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Quick Links */}
        <Box
          flex={1}
          sx={{
            padding: theme.spacing(2),
            overflowY: "auto",
          }}
        >
          <OurFeatures />
        </Box>
      </Box>

      {/* Dialog for Blog Post Details */}
      <Dialog
        open={Boolean(selectedBlog)}
        onClose={handleCloseDialog}
        aria-labelledby="blog-dialog-title"
      >
        <DialogTitle id="blog-dialog-title">{selectedBlog?.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary">
            {selectedBlog?.description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Footer Content */}
      <Grid
        container
        spacing={2}
        sx={{ padding: theme.spacing(2), bgcolor: "#e1f5fe", color: "#fff" }}
      >
        <Grid item xs={12} sm={4}>
          <Typography variant="caption" sx={{ color: "#3f51b5" }}>
            All Rights Reserved
          </Typography>
          <Typography color="#3f51b5">
            © {new Date().getFullYear()} Macrobian Health
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
          <div
            style={{
              flexGrow: 0.1,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              style={{ color: "#3f51b5", textDecoration: "none" }}
              component={Link}
              to="/privacy-policy"
            >
              Privacy
            </Typography>
            <Typography
              style={{ color: "#3f51b5", textDecoration: "none" }}
              component={Link}
              to="/terms"
            >
              Terms of Use
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={4}>
          <SocialMediaLinks />
        </Grid>
      </Grid>
    </div>
  );
};

export default Community;
