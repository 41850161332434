import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  Tooltip,
  Avatar,
  useMediaQuery,
  useTheme,
  Chip,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, NoteAdd as NoteAddIcon, Add as AddIcon, Cancel as CancelIcon, Check as CheckIcon } from "@mui/icons-material";
import axios from "axios";

const SuperuserQuestionnaires = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [questionnaires, setQuestionnaires] = useState([]);
  const [filteredQuestionnaires, setFilteredQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false); // For adding new questions
  const [formData, setFormData] = useState({ question_text: "", options: [] });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchQuestionnaires();
  }, []);

  const fetchQuestionnaires = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/questions");
      setQuestionnaires(response.data);
      setFilteredQuestionnaires(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch questionnaires data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredQuestionnaires(
      questionnaires.filter(
        (questionnaire) =>
          questionnaire.question_text.toLowerCase().includes(value)
      )
    );
  };

  const handleEditIconClick = (questionnaire) => {
    const optionsArray = questionnaire.options ? questionnaire.options.split(",") : [];

    setSelectedQuestionnaire(questionnaire);
    setFormData({...questionnaire, options: optionsArray});
    setOpenEditModal(true);
  };

  const handleAddNewQuestion = () => {
    setFormData({ question_text: "", options: [] });
    setOpenAddModal(true);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.put(`https://api.cope.ke/questions/${selectedQuestionnaire.question_id}`, formData);
      fetchQuestionnaires();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Questionnaire updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update questionnaire data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post("https://api.cope.ke/questions", formData);
      fetchQuestionnaires();
      setOpenAddModal(false);
      setSnackbar({ open: true, message: "New question added successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add question", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://api.cope.ke/questions/${selectedQuestionnaire.question_id}`);
      fetchQuestionnaires();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Questionnaire deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete questionnaire data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold", color: "#00695C" }}>Question</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#00695C" }}>Options</TableCell>
            <TableCell sx={{ fontWeight: "bold", color: "#00695C" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredQuestionnaires.map((questionnaire) => (
            <TableRow key={questionnaire.question_id}>
              <TableCell>
                {questionnaire.question_text.length > 50
                  ? `${questionnaire.question_text.slice(0, 50)}...`
                  : questionnaire.question_text}
              </TableCell>
              <TableCell sx={{ maxWidth: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {questionnaire.options
                ? questionnaire.options.split(",").map((option, index) => (
                    <Chip
                      key={index}
                      label={option.length > 20 ? `${option.trim().slice(0, 20)}...` : option.trim()}
                      sx={{
                        margin: '2px',
                        backgroundColor: '#fdbf57', // Chip background color
                        color: '#fff', // Chip text color
                        fontSize: '14px',
                      }}
                    />
                  ))
                : <Typography variant="body2" color="textSecondary">No options available</Typography>}
            </TableCell>

              <TableCell>
              <Tooltip title="Edit">
                <IconButton onClick={() => handleEditIconClick(questionnaire)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => setOpenDeleteModal(true)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  // Delete Confirmation Modal with Icon Buttons
const renderDeleteModal = () => (
  <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
    <Box sx={modalStyle}>
      <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
      <Typography variant="body1" gutterBottom>
        Are you sure you want to delete this questionnaire?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "16px" }}>
        <IconButton
          onClick={() => setOpenDeleteModal(false)}  // Close the modal on cancel
          sx={{ color: "#fff", backgroundColor: "#1a6051", "&:hover": { backgroundColor: "#14543f" } }}
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          onClick={handleDelete}  // Trigger delete action
          sx={{ color: "#fff", backgroundColor: "#e53935", "&:hover": { backgroundColor: "#b71c1c" } }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  </Modal>
);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color="#00695C" variant="h4" gutterBottom>
        Therapy Questionnaires
      </Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search questionnaires"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewQuestion}
        >
          New
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredQuestionnaires.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No questionnaires found.
        </Typography>
      )}

      {/* Modal for Editing Questions */}
      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: "80%" }}>
          <Typography variant="h6" gutterBottom>Edit Questionnaire</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Question"
            value={formData.question_text || ""}
            onChange={(e) => setFormData({ ...formData, question_text: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Options (comma separated)"
            value={Array.isArray(formData.options) ? formData.options.join(", ") : ""}
            onChange={(e) => setFormData({ ...formData, options: e.target.value.split(",").map(opt => opt.trim()) })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      {/* Modal for Adding New Questions */}
      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAddSubmit} sx={{ ...modalStyle, width: "80%" }}>
          <Typography variant="h6" gutterBottom>Add New Questionnaire</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Question"
            value={formData.question_text}
            onChange={(e) => setFormData({ ...formData, question_text: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Options (comma separated)"
            value={formData.options.join(", ")}
            onChange={(e) => setFormData({ ...formData, options: e.target.value.split(",").map(opt => opt.trim()) })}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      {/* Modal for Deleting Questions */}
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this questionnaire?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserQuestionnaires;
