import React from 'react';
import { Box, Typography, Grid, IconButton, Link } from '@mui/material';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaTiktok } from 'react-icons/fa';

import logo from '../assets/logo.png'; // Replace with your actual logo image path


const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#f1f1f1', padding: '30px 20px', borderTop: '1px solid #e0e0e0' }}>
      {/* Top section with logo, navigation links, and social media icons */}
      <Grid container alignItems="center" justifyContent="space-between" sx={{ marginBottom: '20px' }}>
        
        {/* Left side: Logo */}
        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' }, marginBottom: { xs: '20px', md: '0' } }}>
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Cope Logo" style={{ height: '50px', marginRight: '10px' }} />
          </Box>
        </Grid>

        {/* Center: Navigation Links */}
        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>

          <Link href="#services" underline="none" sx={{ margin: '0 15px', color: '#666', '&:hover': { color: '#000' } }}>
            Services
          </Link>
          <Link href="#blog" underline="none" sx={{ margin: '0 15px', color: '#666', '&:hover': { color: '#000' } }}>
            Blog
          </Link>
          <Link href="#contact" underline="none" sx={{ margin: '0 15px', color: '#666', '&:hover': { color: '#000' } }}>
            Contact
          </Link>
        </Grid>

        {/* Right side: Social Media Icons with brand color hover */}
        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
          <IconButton
            href="#"
            sx={{
              color: '#000',
              '&:hover': {
                backgroundColor: '#3b5998', // Facebook brand color
                color: '#fff',
              },
            }}
          >
            <FaFacebook />
          </IconButton>

          <IconButton
            href="https://www.instagram.com/cope.ke/"
            sx={{
              color: '#000',
              '&:hover': {
                backgroundColor: '#e1306c', // Instagram brand color
                color: '#fff',
              },
            }}
          >
            <FaInstagram />
          </IconButton>

          <IconButton
            href="#"
            sx={{
              color: '#000',
              '&:hover': {
                backgroundColor: '#010101', // TikTok brand color
                color: '#fff',
              },
            }}
          >
            <FaTiktok />
          </IconButton>

          <IconButton
            href="#"
            sx={{
              color: '#000',
              '&:hover': {
                backgroundColor: '#1da1f2', // Twitter brand color
                color: '#fff',
              },
            }}
          >
            <FaTwitter />
          </IconButton>

          <IconButton
            href="#"
            sx={{
              color: '#000',
              '&:hover': {
                backgroundColor: '#0077b5', // LinkedIn brand color
                color: '#fff',
              },
            }}
          >
            <FaLinkedin />
          </IconButton>
        </Grid>
      </Grid>

      {/* Bottom section with additional links */}
      <Grid container justifyContent="center" sx={{ borderTop: '1px solid #e0e0e0', paddingTop: '20px' }}>
        <Link href="#" underline="none" sx={{ margin: '0 15px', color: '#666', '&:hover': { color: '#000' } }}>
          Terms
        </Link>
        <Link href="#" underline="none" sx={{ margin: '0 15px', color: '#666', '&:hover': { color: '#000' } }}>
          Privacy Policy
        </Link>
        <Typography variant="body2" sx={{ margin: '0 15px', color: '#666' }}>
          © 2024 Cope
        </Typography>
      </Grid>
    </Box>
  );
};

export default Footer;
