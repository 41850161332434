import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Collapse, Badge, Divider, Link } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event'; // Appointments
import GroupIcon from '@mui/icons-material/Group'; // Sessions
import AssignmentIcon from '@mui/icons-material/Assignment'; // Assessments
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'; // Treatment
import HealingIcon from '@mui/icons-material/Healing'; // Post Recovery
import PeopleIcon from '@mui/icons-material/People'; // Clients
import SettingsIcon from '@mui/icons-material/Settings'; // User Accounts
import ReceiptIcon from '@mui/icons-material/Receipt'; // Billing
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Exit/Logout
import axios from 'axios'; // For fetching API data
import { useNavigate } from 'react-router-dom';

const AdminMenu = ({
  handleClientsViewClick,
  handleCalendarsViewClick,
  handleBlogPostsViewClick,
  handleResourceCentersViewClick,
  handleTicketsViewClick,
  handleProfileViewClick,
  handleAccountsViewClick,
  handleQuestionaireViewClick,
  handleBillingViewClick,
  handleDashboardViewClick,
  handleAppointmentsViewClick,
  handleAssessmentsViewClick,
  handleSessionsViewClick,
  handleTreatmentsViewClick,
  handlePostRecoveryViewClick,
  handleSubscriptionsViewClick,
  handleEComViewClick,
  handleCalendarViewClick,
}) => {
  const navigate = useNavigate();

  // States for handling dropdowns
  const [openTherapy, setOpenTherapy] = useState(false);
  const [openClients, setOpenClients] = useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [openAccounts, setOpenAccounts] = useState(false);

  // Status states for menu badges
  const [appointmentsStatus, setAppointmentsStatus] = useState(0);
  const [sessionsStatus, setSessionsStatus] = useState(0);
  const [assessmentsStatus, setAssessmentsStatus] = useState(0);
  const [treatmentStatus, setTreatmentStatus] = useState(0);
  const [postRecoveryStatus, setPostRecoveryStatus] = useState(0);
  const [clientsStatus, setClientsStatus] = useState(0);

  // Fetch status counts from respective endpoints
  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const appointmentsResponse = await axios.get('https://cope.ke/appointments?status=pending');
        const sessionsResponse = await axios.get('https://cope.ke/sessions?status=completed');
        const assessmentsResponse = await axios.get('https://cope.ke/assessments?status=pending');
        const treatmentResponse = await axios.get('https://cope.ke/treatments?status=pending');
        const postRecoveryResponse = await axios.get('https://cope.ke/post-recovery?status=new');
        const clientsResponse = await axios.get('https://cope.ke/clients?status=is_active');

        setAppointmentsStatus(appointmentsResponse.data.count || 0);
        setSessionsStatus(sessionsResponse.data.count || 0);
        setAssessmentsStatus(assessmentsResponse.data.count || 0);
        setTreatmentStatus(treatmentResponse.data.count || 0);
        setPostRecoveryStatus(postRecoveryResponse.data.count || 0);
        setClientsStatus(clientsResponse.data.count || 0);
      } catch (error) {
        console.error('Error fetching status data:', error);
      }
    };

    fetchStatuses();
  }, []);

  // Dropdown toggle functions
  const handleTherapyClick = () => setOpenTherapy(!openTherapy);
  const handleClientsClick = () => setOpenClients(!openClients);
  const handleContentClick = () => setOpenContent(!openContent);
  const handleAccountsClick = () => setOpenAccounts(!openAccounts);

  return (
    <List component="nav" aria-label="admin menu">
      {/* Dashboard */}
      <ListItem button onClick={handleDashboardViewClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <EventIcon /> {/* Icon for Dashboard */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Your Dashboard" />
      </ListItem>

      {/* Therapy Dropdown */}
      <ListItem button onClick={handleTherapyClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <LocalHospitalIcon /> {/* Icon for Therapy */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Therapy" />
        {openTherapy ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openTherapy} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4 }} onClick={handleAppointmentsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={appointmentsStatus} color="secondary">
                <EventIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Appointments" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={handleSessionsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={sessionsStatus} color="secondary">
                <GroupIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Sessions" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={handleAssessmentsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={assessmentsStatus} color="secondary">
                <AssignmentIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Assessments" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={handleTreatmentsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={treatmentStatus} color="secondary">
                <LocalHospitalIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Treatment" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={handlePostRecoveryViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={postRecoveryStatus} color="secondary">
                <HealingIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Post Recovery" />
          </ListItem>
        </List>
      </Collapse>

      {/* Clients Dropdown */}
      <ListItem button onClick={handleClientsClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <PeopleIcon /> {/* Icon for Clients */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Clients" />
        {openClients ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openClients} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4 }} onClick={handleClientsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <Badge badgeContent={clientsStatus} color="secondary">
                <PeopleIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Patients" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={handleClientsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Service Providers" />
          </ListItem>
        </List>
      </Collapse>

      {/* Content Marketing */}
      <ListItem button onClick={handleBlogPostsViewClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <EventIcon /> {/* Icon for Blog Posts */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Blog Posts" />
      </ListItem>

      {/* Billing */}
      <ListItem button onClick={handleBillingViewClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Billing" />
      </ListItem>

      {/* User Accounts Dropdown */}
      <ListItem button onClick={handleAccountsClick} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <SettingsIcon /> {/* Icon for User Accounts */}
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="User Accounts" />
        {openAccounts ? <ExpandLess sx={{ color: '#00695C' }} /> : <ExpandMore sx={{ color: '#00695C' }} />}
      </ListItem>
      <Collapse in={openAccounts} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button sx={{ pl: 4 }} onClick={handleAccountsViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="View Accounts" />
          </ListItem>
          <ListItem button sx={{ pl: 4 }} onClick={handleProfileViewClick}>
            <ListItemIcon sx={{ color: '#00695C' }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: '#00695C' }} primary="Your Profile" />
          </ListItem>
        </List>
      </Collapse>

      <Divider />

      {/* Logout */}
      <ListItem button onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
        <ListItemIcon sx={{ color: '#00695C' }}>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText sx={{ color: '#00695C' }} primary="Sign Out" />
      </ListItem>
    </List>
  );
};

export default AdminMenu;
