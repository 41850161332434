import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Snackbar,
  Alert,
  Chip,
  Breadcrumbs,
  Link,
  Avatar,
  Tooltip,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, Upload as UploadIcon } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle, faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

const SuperuserUserAccounts = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formData, setFormData] = useState({ username: "", email: "", phone: "", avatar: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.cope.ke/api/accounts");
      setAccounts(response.data);
      setFilteredAccounts(response.data);
      setLoading(false);
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to fetch accounts data", severity: "error" });
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAccounts(
      accounts.filter(
        (account) =>
          account.username.toLowerCase().includes(value) ||
          account.email.toLowerCase().includes(value)
      )
    );
  };

  const handleEditIconClick = (account) => {
    setSelectedAccount(account);
    setFormData({ username: account.username, email: account.email, phone: account.phone, avatar: account.avatar });
    setOpenEditModal(true);
  };

  const handleDeleteIconClick = (account) => {
    setSelectedAccount(account);
    setOpenDeleteModal(true);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!validateEmail(formData.email)) {
      setSnackbar({ open: true, message: "Invalid email format", severity: "error" });
      setIsSubmitting(false);
      return;
    }
    try {
      await axios.put(`https://api.cope.ke/api/accounts/${selectedAccount.account_id}`, formData);
      fetchAccounts();
      setOpenEditModal(false);
      setSnackbar({ open: true, message: "Account updated successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to update account data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await axios.delete(`https://api.cope.ke/api/account/${selectedAccount.account_id}`);
      fetchAccounts();
      setOpenDeleteModal(false);
      setSnackbar({ open: true, message: "Account deleted successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to delete account data", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleAddSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (!validateEmail(formData.email)) {
      setSnackbar({ open: true, message: "Invalid email format", severity: "error" });
      setIsSubmitting(false);
      return;
    }
    try {
      await axios.post("https://api.cope.ke/api/accounts", formData);
      fetchAccounts();
      setOpenAddModal(false);
      setSnackbar({ open: true, message: "Account created successfully", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to add account", severity: "error" });
    }
    setIsSubmitting(false);
  };

  const handleResendActivation = async (userId) => {
    try {
      await axios.post(`https://api.cope.ke/api/resend-activation/${userId}`);
      setSnackbar({ open: true, message: "Activation link sent!", severity: "success" });
    } catch (error) {
      setSnackbar({ open: true, message: "Failed to send activation link. Please try again.", severity: "error" });
    }
  };

  const renderCards = () => (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {filteredAccounts.map((account) => (
        <Card key={account.account_id} sx={{ width: 300, m: 2 }}>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Avatar src={account.avatar || ''}>
                {!account.avatar && <FontAwesomeIcon icon={faUser} size="2x" />}
              </Avatar>
              <Box>
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEditIconClick(account)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDeleteIconClick(account)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Typography variant="h6">{account.username}</Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faEnvelope} /> {account.email}
            </Typography>
            <Typography variant="body2">
              <FontAwesomeIcon icon={faPhone} /> {account.phone}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" sx={{ mr: 1 }}>Status:</Typography>
              {account.is_active === 0 ? (
                <Chip label="Inactive" color="error" />
              ) : (
                <Chip label="Active" color="success" />
              )}
              {account.is_active === 0 && (
                <Tooltip title="Resend Activation Link">
                  <IconButton onClick={() => handleResendActivation(account.account_id)}>
                    <FontAwesomeIcon icon={faRecycle} color="green" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>    
      <Typography variant="h4" gutterBottom>User Accounts</Typography>
      <Box display="flex" alignItems="center" mb={2}>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search accounts"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <SearchIcon />
            ),
          }}
          sx={{ mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setFormData({ username: "", email: "", phone: "", avatar: "" });
            setOpenAddModal(true);
          }}
          sx={{ padding: isMobile ? '8px 12px' : '8px 16px' }}
        >
          Add User
        </Button>
      </Box>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredAccounts.length > 0 ? (
        renderCards()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No user accounts found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleEditSubmit} sx={{ ...modalStyle, width: 400 }} noValidate>
          <Typography variant="h6" gutterBottom>Edit Account</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Name"
            name="username"
            value={formData.username || ""}
            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <TextField
            margin="normal"
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
            value={formData.phone || ""}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
          />
          <Button
            component="label"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Upload Picture
            <UploadIcon sx={{ ml: 1 }} />
            <input
              type="file"
              hidden
              onChange={(e) => setFormData({ ...formData, avatar: URL.createObjectURL(e.target.files[0]) })}
            />
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)} sx={{ overflowY: "auto" }}>
        <Box component="form" onSubmit={handleAddSubmit} sx={{ ...modalStyle, width: 400,  color:"#2C1C18" }} noValidate>
          <Typography variant="h6" gutterBottom>Create Account</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Name"
            name="username"
            value={formData.username || ""}
            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
            InputProps={{
              style: {
                color: '#2C1C18', // Dark text color
              },
            }}
            InputLabelProps={{
              style: {
                color: '#2C1C18', // Dark label color
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            value={formData.email || ""}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            InputProps={{
              style: {
                color: '#2C1C18', // Dark text color
              },
            }}
            InputLabelProps={{
              style: {
                color: '#2C1C18', // Dark label color
              },
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            id="phone"
            label="Phone"
            name="phone"
            value={formData.phone || ""}
            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
            InputProps={{
              style: {
                color: '#2C1C18', // Dark text color
              },
            }}
            InputLabelProps={{
              style: {
                color: '#2C1C18', // Dark label color
              },
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Modal>

      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)} sx={{ overflowY: "auto" }}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>Confirm Delete</Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this user account?
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : "Delete"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDeleteModal(false)}
            sx={{ mt: 1 }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default SuperuserUserAccounts;
