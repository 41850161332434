import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  TextField,
  CircularProgress,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Toolbar,
} from "@mui/material";
import { Search as SearchIcon, Edit as EditIcon } from "@mui/icons-material";
import axios from "axios";

const UserSettings = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [providers, setProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProviders = async () => {
    try {
      const response = await axios.get("https://api.cope.ke/service_providers");
      setProviders(response.data);
      setFilteredProviders(response.data);
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch service providers");
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredProviders(
      providers.filter(
        (provider) =>
          provider.contactName.toLowerCase().includes(value) ||
          provider.email.toLowerCase().includes(value) ||
          provider.phoneNumber.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenEditModal = (provider) => {
    setSelectedProvider(provider);
    setFormData(provider);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setFormData({});
    setSelectedProvider(null);
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await axios.put(`https://api.cope.ke/service_providers/${selectedProvider.id}`, formData);
      setSuccess("Service provider updated successfully!");
      fetchProviders();
      handleCloseEditModal();
    } catch (error) {
      setError("Failed to update service provider");
      setLoading(false);
    }
  };

  const handleCloseError = () => setError("");
  const handleCloseSuccess = () => setSuccess("");

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? "small" : "medium"}>
        <TableHead>
          <TableRow>
            <TableCell>Contact Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProviders.map((provider) => (
            <TableRow key={provider.id} onClick={() => handleOpenEditModal(provider)}>
              <TableCell>{provider.contactName}</TableCell>
              <TableCell>{provider.email}</TableCell>
              <TableCell>{provider.phoneNumber}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={(e) => { e.stopPropagation(); handleOpenEditModal(provider); }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>My Settings</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search providers"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredProviders.length > 0 ? (
        renderTable()
      ) : (
        <Typography variant="body1" sx={{ mt: 4 }}>
          No service providers found.
        </Typography>
      )}

      <Modal open={openEditModal} onClose={handleCloseEditModal} sx={{ overflowY: "auto" }}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          <Typography variant="h6" gutterBottom>
            Edit User
          </Typography>
          <Box component="form" onSubmit={handleEditSubmit} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              id="contactName"
              label="Contact Name"
              name="contactName"
              value={formData.contactName || ""}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
              type="email"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber || ""}
              onChange={handleChange}
              type="tel"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={Boolean(error)} onClose={handleCloseError}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Error
          </Typography>
          <Typography variant="body1">
            {error}
          </Typography>
          <Button onClick={handleCloseError} color="primary">
            OK
          </Button>
        </Box>
      </Modal>

      <Modal open={Boolean(success)} onClose={handleCloseSuccess}>
        <Box sx={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Success
          </Typography>
          <Typography variant="body1">
            {success}
          </Typography>
          <Button onClick={handleCloseSuccess} color="primary">
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  overflowY: "auto",
};

export default UserSettings;