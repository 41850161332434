import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Toolbar,
  TextField,
  InputAdornment,
  Modal,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip
} from '@mui/material';
import {
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Check as ApproveIcon,
  Schedule as RescheduleIcon,
  Cancel as CancelIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';
import { UserContext } from "../UserContext";

const ServiceProviderAppointments = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const { user } = useContext(UserContext);
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    status: ''
  });

  useEffect(() => {
    fetchAppointments();
  }, []);

  const fetchAppointments = async () => {
    try {
      const response = await axios.get(`https://api.cope.ke/appointments/${user.provider_id}`);
      const appointments = response.data
      setAppointments([appointments]);
      setFilteredAppointments([appointments]);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch appointments', error);
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    setFilteredAppointments(
      appointments.filter(
        (appointment) =>
          appointment.company_name.toLowerCase().includes(value) ||
          appointment.email.toLowerCase().includes(value) ||
          appointment.phone.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenDetailsModal = (appointment) => {
    setSelectedAppointment(appointment);
    setFormData(appointment);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setSelectedAppointment(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
      date: '',
      status: ''
    });
  };

  const handleOpenAddEditModal = (appointment = null) => {
    setSelectedAppointment(appointment);
    setFormData(appointment || {
      name: '',
      email: '',
      phone: '',
      date: '',
      status: ''
    });
    setOpenAddEditModal(true);
  };

  const handleCloseAddEditModal = () => {
    setOpenAddEditModal(false);
    setSelectedAppointment(null);
    setFormData({
      name: '',
      email: '',
      phone: '',
      date: '',
      status: ''
    });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSave = async () => {
    try {
      if (selectedAppointment) {
        await axios.put(`https://api.cope.ke/service_provider_appointments/${selectedAppointment.id}`, formData);
      } else {
        await axios.post('https://api.cope.ke/service_provider_appointments', formData);
      }
      fetchAppointments();
      handleCloseAddEditModal();
    } catch (error) {
      console.error('Failed to save appointment', error);
    }
  };

  const handleDelete = async (appointmentId) => {
    try {
      await axios.delete(`https://api.cope.ke/service_provider_appointments/${appointmentId}`);
      fetchAppointments();
    } catch (error) {
      console.error('Failed to delete appointment', error);
    }
  };

  const handleApprove = async (appointment) => {
    try {
      const { name, email } = appointment;
      await axios.post('../sp-assessment', { name, email });
      await axios.put(`https://api.cope.ke/service_provider_appointments/${appointment.provider_id}`, { status: 'Approved' });
      fetchAppointments();
    } catch (error) {
      console.error('Failed to approve appointment', error);
    }
  };

  const handleReschedule = async (appointmentId, newDate) => {
    try {
      await axios.put(`https://api.cope.ke/service_provider_appointments/${appointmentId}`, { date: newDate });
      fetchAppointments();
    } catch (error) {
      console.error('Failed to reschedule appointment', error);
    }
  };

  const handleCancel = async (appointmentId) => {
    try {
      await axios.put(`https://api.cope.ke/service_provider_appointments/${appointmentId}`, { status: 'Cancelled' });
      fetchAppointments();
    } catch (error) {
      console.error('Failed to cancel appointment', error);
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size={isMobile ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAppointments.length > 0 ? (
            filteredAppointments.map((appointment) => (
              <TableRow key={appointment.provider_id} onClick={() => handleOpenDetailsModal(appointment)}>
                <TableCell>{appointment.user_name}</TableCell>
                <TableCell>{appointment.user_email}</TableCell>
                <TableCell>{new Date(appointment.appointment_date).toLocaleString()}</TableCell>
                <TableCell>{appointment.status}</TableCell>
                <TableCell>
                  <Tooltip title="Confirm Appointment" arrow>
                    <IconButton
                      onClick={(e) => { e.stopPropagation(); handleApprove(appointment); }}
                      sx={{ color: '#00695C', '&:hover': { color: '#004D40' } }}
                    >
                      <ApproveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit Appointment" arrow>
                    <IconButton
                      onClick={(e) => { e.stopPropagation(); handleOpenAddEditModal(appointment); }}
                      sx={{ color: '#00695C', '&:hover': { color: '#004D40' } }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reschedule Appointment" arrow>
                    <IconButton
                      onClick={(e) => { e.stopPropagation(); handleReschedule(appointment.provider_id, appointment.appointment_date); }}
                      sx={{ color: '#00695C', '&:hover': { color: '#004D40' } }}
                    >
                      <RescheduleIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel Appointment" arrow>
                    <IconButton
                      onClick={(e) => { e.stopPropagation(); handleCancel(appointment.provider_id); }}
                      sx={{ color: '#00695C', '&:hover': { color: '#004D40' } }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} sx={{ color: 'red' }} align="center">
                No appointments available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDetails = () => (
    <Box>
      <IconButton onClick={handleCloseDetailsModal}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6">Appointment Details</Typography>
      <Box component="form" sx={{ mt: 1 }}>
        {/* Form fields remain unchanged */}
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography color='#00695C' variant="h4" gutterBottom>Your Appointments</Typography>
      <Toolbar>
        <TextField
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search appointments"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : selectedAppointment ? (
        renderDetails()
      ) : (
        renderTable()
      )}

      <Modal open={openAddEditModal} onClose={handleCloseAddEditModal}>
        <Box component={Paper} sx={{ ...modalStyle, width: isMobile ? '90%' : 400 }}>
          {renderDetails()}
        </Box>
      </Modal>
    </Container>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  borderRadius: 2,
};

export default ServiceProviderAppointments;
